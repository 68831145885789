<template>
	<div class="flex flex-row flex-wrap justify-start items-start w-screen sm:w-auto h-full px-4 py-6 sm:px-6 sm:py-12">
		<!-- Назад -->
		<router-link :to="{ name: 'Activities' }" class="flex sm:hidden justify-center items-center border-2 border-blue-500 text-blue-500 font-semibold p-2 pl-3 rounded-xl mb-4">
			<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 1L1.70711 5.29289C1.31658 5.68342 1.31658 6.31658 1.70711 6.70711L6 11" stroke="#3377FF" stroke-width="2" stroke-linecap="round" />
			</svg>
			<p class="pl-3">Назад</p>
		</router-link>

		<!-- Заголовок -->
		<h1 class="flex w-full text-xl mb-4 sm:mb-7 font-extrabold sm:text-2xl">
			{{ viewInfoObject.fio }}
		</h1>

		<!-- Информация -->
		<div class="flex flex-col justify-between w-full px-5 py-8 sm:w-7/12 sm:h-40 sm:mr-4 white-round-box">
			<p class="flex h-5 my-2 text-md text-gray-400 font-bold">
				{{ viewInfoObject.block.title }}
			</p>
			<p class="flex h-5 my-2 text-lg text-gray-900 font-bold mt-6">
				{{ viewInfoObject.block.value }}
			</p>
		</div>

		<!-- Блок с информацией -->
		<div class="flex flex-col w-full h-auto mt-4 rounded-lg bg-white filter shadow-default relative">
			<div class="table w-full rounded-lg bg-white">
				<!-- Заголовки -->
				<div v-show="viewInfoObject.block.flagMO" class="thead content-center flex px-4 sm:px-8 sm:py-5 border-b sm:border-gray-100">
					<div v-for="(item, key) in viewInfoObject.fields" :key="'A' + key" class="sm:relative flex w-full" :class="{ 'sm:w-4/12': key === 0, 'sm:w-2/12': key != 0 }">
						<span
							class="table-cell w-full my-auto text-gray-400 cursor-pointer font-normal justify-between items-center"
							:class="{ 'text-blue-500 underline': filterBy === key }"
							@click="switchFilterVisibility(key)"
						>
							<!-- :class="{ 'border-b': params.filters.find((f) => f.fieldName === item.fieldName) }" -->
							<span>
								{{ item.Name }}
							</span>
							<!-- eslint-disable max-len -->
							<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
									fill="#9193A5"
								/>
							</svg>
							<!-- eslint-enable max-len -->
						</span>
						<!-- Фильтр и сортировка -->
						<div class="fixed flex left-0 right-0 bottom-0 top-0 bg-black50 sm:bg-white sm:rounded-xl sm:absolute sm:top-20 sm:left-6 z-50" v-if="filterOpen === key">
							<form class="flex m-auto flex-col items-start w-72 py-5 bg-white rounded-xl shadow-lg popup-filter" onsubmit="return false;">
								<h3 class="flex px-5 text-gray-400 font-bold uppercase justify-between items-center w-full">
									<span>Сортировка</span>
									<svg @click="switchFilterVisibility(key)" width="28" class="sm:hidden" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M10.5625 17.5625L17.5625 10.5625" stroke="#3377FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5625 17.5625L10.5625 10.5625" stroke="#3377FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</h3>
								<label class="mt-4 px-5 cursor-pointer font-normal">
									По возрастанию (А-Я)
									<input class="custom-input relative" type="radio" name="sortBy" @change="sortFilter(filterData)" v-model="filterData.sortBy" value="asc" />
								</label>
								<label class="mt-4 px-5 cursor-pointer font-normal">
									По убыванию (Я-А)
									<input class="custom-input relative" type="radio" name="sortBy" @change="sortFilter(filterData)" v-model="filterData.sortBy" value="desc" />
								</label>
								<h3 class="flex items-center w-full mt-5 pt-5 px-5 border-t border-gray-400 text-gray-400 font-bold uppercase">
									Фильтр
									<button type="reset" class="ml-auto text-xs font-normal text-blue-500 underline normal-case cursor-pointer" @click="resetFilter">Сбросить фильтр</button>
								</h3>
								<input
									type="text"
									v-model="filterData.searchValue"
									v-filter="item.paramsFiter"
									@input="search(filterData)"
									placeholder="Введите текст..."
									class="flex flex-grow mt-10 ml-3 pl-2 w-64 outline-none border-b border-gray-400"
								/>
							</form>
						</div>
					</div>
				</div>
				<!-- Раскрывающий список назначенных МО для мобильных -->
				<div class="td-mob" v-show="viewInfoObject.block.flagMO" v-for="(item, rowKey) in list" :key="'C' + rowKey">
					<div v-if="item.isComplete" class="complite flex text-green-500">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect width="20" height="20" rx="2" fill="#27AE60" />
							<path d="M4 9.95L9.07692 14L16 5" stroke="white" stroke-width="3" />
						</svg>
						<span class="pl-2.5">Проверено</span>
					</div>
					<div v-else class="complite flex text-gray-400">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="1" y="1" width="18" height="18" rx="1" stroke="#A8B1CE" stroke-width="2" />
						</svg>
						<span class="pl-2.5">Непроверено</span>
					</div>
					<div class="name mt-2.5 text-gray-500 text-sm">
						<p>
							{{ item.name }}
						</p>
					</div>
				</div>

				<!-- Раскрывающий список последней активности для мобильных -->
				<div class="trow" v-if="viewInfoObject.block.flagAction">
					<div
						v-for="(item, rowKey) in list"
						:key="'D' + rowKey"
						:class="{ active: item.flagOpen }"
						class="w-full justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0"
						@click="item.flagOpen = !item.flagOpen"
					>
						<span class="table-cell" :class="{ 'text-gray-500': !item.flagOpen, 'text-white bg-blue-500': item.flagOpen }">
							<span class="flex-grow">{{ item.name }}</span>
							<svg class="flex-grow-0 mt-1" :class="{ 'transform rotate-180': item.flagOpen }" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1"
									:stroke="item.flagOpen ? '#fff' : '#9193A5'"
									stroke-width="2"
									stroke-linecap="round"
								/>
							</svg>
						</span>

						<div v-show="item.flagOpen" class="sm:hidden bg-liteGray max-h-96 overflow-y-auto">
							<div class="p-5 text-sm border-b border-gray-200; text-gray-500" v-for="(it, ind) in item.array" :key="'F' + ind">
								<div class="label mb-2.5 font-semibold text-sm">
									{{ new Date(it.created).toLocaleDateString() }}
								</div>
								<div class="value text-sm">
									{{ new Date(it.created).toLocaleTimeString() }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Футер -->
				<div v-show="viewInfoObject.block.flagMO" class="block sm:hidden flex-row pt-6 px-20 pb-12 text-gray-400 text-sm">
					<div class="flex flex-row ml-auto justify-around w-full">
						<div @click="prevPage" class="item flex bg-gray-100 w-9 h-9 text-center rounded-md hover:bg-blue-500 hover:color-white cursor-pointer">
							<svg class="m-auto" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5 1L1.70711 4.29289C1.31658 4.68342 1.31658 5.31658 1.70711 5.70711L5 9" stroke="#A8B1CE" stroke-width="2" stroke-linecap="round" />
							</svg>
						</div>
						<div class="item flex bg-gray-100 w-20 h-9 text-center rounded-md">
							<span class="m-auto">Стр {{ page + 1 }} из {{ totalCount == 0 ? 1 : totalCount }}</span>
						</div>
						<div @click="nextPage" class="item flex bg-gray-100 w-9 h-9 text-center rounded-md hover:bg-blue-500 hover:color-white cursor-pointer transform rotate-180">
							<svg width="6" class="m-auto" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5 1L1.70711 4.29289C1.31658 4.68342 1.31658 5.31658 1.70711 5.70711L5 9" stroke="#A8B1CE" stroke-width="2" stroke-linecap="round" />
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
	name: 'MobileInfoView',
	data() {
		return {
			flag0: false,
			flag1: false,
			flag2: false,
			flag3: false,
			flag4: false,
			openContentChange: false,
			totalCount: 0,
			rowsVisible: 5,
			page: 0,
			commentsVisible: null,
			filterOpen: null,
			filterBy: null,
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			listDefault: [],
			list: [],
			viewInfoObject: {
				fio: '',
				block: {
					title: '',
					value: '',
					flagMO: false,
					flagAction: false
				},
				fields: [
					{
						Name: 'Назначенные МО',
						paramsFiter: '[a-zA-Zа-яА-Я]'
					}
				],
				url: ''
			}
		};
	},
	methods: {
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		switchCommentsVisibility(key) {
			this.commentsVisible = this.commentsVisible === key ? '' : key;
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
		},
		resetFilter() {
			this.filterData = {
				sortBy: '',
				searchValue: ''
			};
			this.page = 0;
			this.list = this.paginatedDataComputed(this.page, this.rowsVisible, this.listDefault);
		},
		search: debounce(function (obj) {
			if (!obj.searchValue) {
				this.list = this.paginatedDataComputed(this.page, this.rowsVisible, this.listDefault);

				return;
			}
			this.page = 0;
			setTimeout(() => {
				if (obj.sortBy && !obj.searchValue) {
					this.list = this.paginatedDataComputed(this.page, this.rowsVisible, this.listDefault.sort(obj.sortBy === 'asc' ? this.compareAB : this.compareBA));
				} else if (!obj.sortBy && obj.searchValue) {
					this.list = this.paginatedDataComputed(
						this.page,
						this.rowsVisible,
						this.listDefault.filter((f) => f.name.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1)
					);
				} else if (obj.sortBy && obj.searchValue) {
					let res = [];
					res = this.listDefault.filter((f) => f.name.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1);

					this.list = this.paginatedDataComputed(this.page, this.rowsVisible, res.sort(obj.sortBy === 'asc' ? this.compareAB : this.compareBA));
				}
			}, 100);
		}, 1000),
		sortFilter(obj) {
			this.page = 0;

			if (obj.sortBy && !obj.searchValue) {
				this.list = this.paginatedDataComputed(this.page, this.rowsVisible, this.listDefault.sort(obj.sortBy === 'asc' ? this.compareAB : this.compareBA));
			} else if (!obj.sortBy && obj.searchValue) {
				this.list = this.paginatedDataComputed(
					this.page,
					this.rowsVisible,
					this.listDefault.filter((f) => f.name.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1)
				);
			} else if (obj.sortBy && obj.searchValue) {
				let res = [];
				res = this.listDefault.filter((f) => f.name.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1);

				this.list = this.paginatedDataComputed(this.page, this.rowsVisible, res.sort(obj.sortBy === 'asc' ? this.compareAB : this.compareBA));
			}
		},
		compareAB(a, b) {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		},
		compareBA(a, b) {
			if (a.name < b.name) {
				return 1;
			}
			if (a.name > b.name) {
				return -1;
			}
			return 0;
		},
		groupBy(data, key) {
			return data.reduce((acc, cur) => {
				acc[cur[key]] = acc[cur[key]] || [];
				acc[cur[key]].push(cur);

				return acc;
			}, {});
		},
		getData() {
			this.$http
				.post(`${this.viewInfoObject.url}`, {
					currentPage: 0,
					filters: [],
					pageSize: 999999,
					sortOption: {
						fieldName: '',
						sortType: null
					}
				})
				.then((res) => {
					this.listDefault = res.data.data;

					if (this.viewInfoObject.block.flagMO) {
						this.list = this.paginatedDataComputed(this.page, this.rowsVisible, this.listDefault);
					}

					if (this.viewInfoObject.block.flagAction) {
						let arr1 = this.groupBy(res.data.lastActions.data, 'actionStr');

						let arr2 = Object.entries(arr1);

						for (let i = 0; i < arr2.length; i += 1) {
							this.list.push({
								flagOpen: false,
								name: arr2[i][0],
								array: arr2[i][1]
							});
						}

						// console.log(this.list);
					}
				});
		},
		nextPage() {
			if (this.totalCount > this.page + 1) {
				this.page += 1;
				this.list = this.paginatedDataComputed(this.page, this.rowsVisible, this.listDefault);
			}
		},
		prevPage() {
			if (this.page !== 0) {
				this.page -= 1;
				this.list = this.paginatedDataComputed(this.page, this.rowsVisible, this.listDefault);
			}
		},
		paginatedDataComputed(page, rowsVisible, list) {
			let start = page * rowsVisible,
				end = start + rowsVisible;

			this.totalCount = Math.ceil(list.length / rowsVisible);

			return list.slice(start, end);
		}
	},
	created() {
		this.viewInfoObject = {
			fio: this.$route.query.fio,
			block: {
				title: this.$route.query.title,
				value: this.$route.query.value,
				flagMO: JSON.parse(this.$route.query.flagMO),
				flagAction: JSON.parse(this.$route.query.flagAction)
			},
			fields: [
				{
					Name: this.$route.query.fields,
					paramsFiter: '[a-zA-Zа-яА-Я]'
				}
			],
			url: this.$route.query.url
		};

		this.getData();
	}
};
</script>

<style>
.td-mob {
	@apply p-4 bg-white;
	border: 1px solid rgba(168, 177, 206, 0.2);
}
.mob-item {
	@apply p-5 text-sm border-b border-gray-200;
}
.white-round-box {
	@apply sm:px-10 sm:py-8 rounded-lg bg-white filter drop-shadow-default;
}
.toggle-checkbox:checked {
	@apply: right-0 border-blue-500;
	right: 0;
	border-color: #3b82f6;
}
.toggle-checkbox:checked + .toggle-label {
	@apply: bg-blue-500;
	background-color: #3b82f6;
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 500ms ease-out;
}

.fade-leave-to {
	opacity: 0;
}

.table-cell {
	@apply flex flex-wrap p-5 px-7 text-sm text-left;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.custom-input:not(:checked) {
	display: none;
}

.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	background: url('~@/assets/img/filter-tick.svg') no-repeat;
	z-index: 40;
}
</style>
